
// 图片设置 宽/高 3/2
// let sevenEightImages = document.getElementsByClassName('img-seven-eight')
// for(let i=0;i<sevenEightImages.length;i++){
//     let width = sevenEightImages[i].clientWidth
//     sevenEightImages[i].setAttribute('style','height:'+width*0.78+'px')
// }


// 产品详情页参数标题补充背景

let widthZh = document.getElementsByClassName('title-zh')
let widthEh = document.getElementsByClassName('title-en-text')
let widthK = document.getElementsByClassName('title-en-text-k')
// console.log(width[0].offsetWidth)

for(let i=0;i<widthZh.length;i++){
    let widthZ = widthZh[i].offsetWidth
    let widthE = widthEh[i].offsetWidth
    let width = widthZ - widthE

    widthK[i].setAttribute('style','width:'+width+'px')
}
